<template>
  <div class="login">
    <sign-in />
  </div>
</template>
<script>
import SignIn from '@/components/SignIn'
export default {
  components: {
    SignIn,
  },
}
</script>
